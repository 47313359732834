export default {
  EST_START_SCREEN: 'showStartScreenState',
  EST_IDLE: 'idleState',
  EST_INIT: 'initState',
  EST_REQUEST_ROLLING: 'requestRollingState',
  EST_MINIMAL_ROLLING: 'minimalRollingState',
  EST_STOP_ROLLING: 'stopRollingState',
  EST_SHOW_WIN: 'showWinState',
  EST_STOP_WIN: 'stopWinState',
  EST_JACKPOT_GAME_IN: 'jackpotGameInState',
  EST_JACKPOT_GAME_IDLE: 'jackpotGameIdleState',
  EST_JACKPOT_GAME_OUT: 'jackpotGameOutState',
  EST_SHOW_WON_JACKPOT_GAME: 'showWonJackpotGameState',
}
