import EntryPoint from 'Engine/EntryPoint';

import gameConfig from '../../assets/config.json';
import { getAssetsScale } from 'Engine/utils/getAssetsScale';
import UbuntuBold from '../../assets/fonts/ubuntu/UBUNTU-B.TTF';
import UbuntuRegular from '../../assets/fonts/ubuntu/UBUNTU-R.TTF';
import MyriadPro from '../../assets/fonts/myriadpro/MYRIADPRO-BOLD.OTF';
import RevansMedium from '../../assets/fonts/revans/Revans-Medium.ttf';
import Config from '../Config';
import eSoundType from '../sounds/eSoundType';

import stoneParticle0 from '../../assets/images/jackpotGame/part_1.png'
import stoneParticle1 from '../../assets/images/jackpotGame/part_2.png'
import stoneParticle2 from '../../assets/images/jackpotGame/part_3.png'

import flashlib from '../../assets/exported/game/FlashLib.json';
import jackpotFlashlib from  '../../assets/exported/jackpotGame/FlashLib.json';

// import assets from '../../assets/exported/game/FlashLibAssets';
// import jackpotGameAssets from '../../assets/exported/jackpotGame/FlashLibAssets';
// import jackpotGameMobileAssets from '../../assets/exported/jackpotGameMobile/FlashLibAssets';

import eAnimationTypes from '../enums/eAnimationTypes';

const scale = getAssetsScale({
  availableScales: ['0.7','0.75','0.8','0.9'],
  safeWidthLandscape: Config.SAFE_WIDTH_LANDSCAPE,
  safeHeightLandscape: Config.SAFE_HEIGHT_LANDSCAPE,
  safeWidthPortrait: Config.SAFE_WIDTH_PORTRAIT,
  safeHeightPortrait: Config.SAFE_HEIGHT_PORTRAIT,
})

const assets = `flashlib/game/AssetsCombined@${scale}x.json`;
const jackpotAssets = `flashlib/jackpotGame/AssetsCombined@${scale}x.json`;

const config = {
  assets: [
    { name: 'GameAssets', path: assets, extension: 'json' },
    { name: 'GameFlashLib', path: flashlib, extension: 'json' },
    { name: 'jackpotGameAssets', path: jackpotAssets, extension: 'json' },
    { name: 'jackpotGameFlashLib', path: jackpotFlashlib, extension: 'json' },
    { name: 'gameConfig', path: gameConfig, extension: 'json' },
    { name: eAnimationTypes.EAT_SYMBOLS, path: 'animations/symbols/symbols_hot_and_spicy_optimazed1.json', extension: 'json' },
    { name: eAnimationTypes.SYMBOL_FRAME_AND_FIRE, path: 'animations/symbolFrameAndFire/skeleton_optimazed1.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BACKGROUND, path: 'animations/background/bg_animation_hot_and_spicy1.json', extension: 'json' },
    {name: 'stoneParticle0', path: stoneParticle0, extension: 'png'},
    {name: 'stoneParticle1', path: stoneParticle1, extension: 'png'},
    {name: 'stoneParticle2', path: stoneParticle2, extension: 'png'},
  ],
  delayedAssets: [

    { name: eAnimationTypes.EAT_JACKPOT, path: 'animations/jackpot/bonus_game.json', extension: 'json' },
    { name: eAnimationTypes.EAT_BIG_WIN, path: 'animations/bigWins/DWB_b_o_e1.json', extension: 'json' },
  ],
  fonts: [
    { name: 'UbuntuBold',
      path: UbuntuBold,
    },
    {
      name: 'UbuntuRegular',
      path: UbuntuRegular,
    },
    {
      name: 'Myriad Pro',
      path: MyriadPro,
    },{
      name: 'RevansMedium',
      path: RevansMedium,
    },
  ],
  sounds: eSoundType
}

export default config;
