import BaseState from "Engine/base/states/BaseState";
import eAnimationTypes from '../enums/eAnimationTypes';
export default class ShowWonJackpotGameState extends BaseState {
  constructor(stateData) {
    super(stateData);
  }

  async start() {
    window.OPWrapperService.infoPanel.setGameState(window.OPWrapperService.infoPanel.gameStateTypes.EGST_JACKPOT_GAME);
    if (!PIXI.Assets.get(eAnimationTypes.EAT_JACKPOT)) {
      OPPreloader.showDelayedPreloader();
      await PIXI.Assets.load(eAnimationTypes.EAT_JACKPOT);
      OPPreloader.hideDelayedPreloader();
    }
    super.start();
  }
}
