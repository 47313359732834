import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import StatesManager from 'Engine/base/states/StatesManager';
import { wonJackpotGameFontStyle } from '../../../fontStyles';

export default class PopupWonJackpotGame extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.visible = false
    this.alpha = 0;
    this.startButton = this.getChildByName('startButton')
    this.startButton.enabled = true;
    this.setTextsStyle()
    this.addActions();
  }

  setTextsStyle() {
    const textJackpoGame = this.getChildByName('popup_won_jackpot_game_jacpot_game');
    textJackpoGame.text = window.OPWrapperService.localizations.getLocalizedText(
      window.OPWrapperService.config.jackpotEnabled
        ?'popup_won_jackpot_game_jacpot_game'
        : 'popup_won_jackpot_game_mystery_bonus'
  );
    textJackpoGame.style = { ...textJackpoGame.style, ...wonJackpotGameFontStyle }
    if (!window.OPWrapperService.config.jackpotEnabled) {
      textJackpoGame.style.fontSize = 90;
      textJackpoGame.y = textJackpoGame.displayData.y - 30;
    }
    const textStart = this.startButton.getChildByName('popup_won_jackpot_game_button_start')
    textStart.style = {
      ...textStart.style,
      dropShadow: true,
      dropShadowAlpha: 0.82,
      dropShadowAngle: 1.5,
      dropShadowBlur: 10,
      dropShadowDistance: 6,}
  }

  addActions() {
    this.startButton.on('pointertap', this.onButtonClick, this);
  }

  onButtonClick() {
    StatesManager.goToNextState();
    this.startButton.enabled = false;
  }

  show() {
    this.visible = true;
    this.startButton.enabled = true;
    gsap.to(this, { alpha: 1, duration: 0.6,
      onComplete: () => window.OPWrapperService.infoPanel.setGameState(
        window.OPWrapperService.config.jackpotEnabled
          ? window.OPWrapperService.infoPanel.gameStateTypes.EGST_JACKPOT_GAME
          : window.OPWrapperService.infoPanel.gameStateTypes.EGST_MYSTERY_BONUS_GAME
      )});
  }

  hide(){
    gsap.to(this, { alpha: 0, duration: 0.6, onComplete: () => this.visible = false});
  }
}
