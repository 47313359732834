import FlashLib from 'flashlib_onlyplay';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import GameModel from './model/GameModel';
import BaseSlotWinningsController from 'Engine/base/winings/BaseSlotWinningsController';
import './style.css';
import { gsap } from 'gsap';
import eStateTypes from './enums/eStateTypes';
import eEventTypes from './enums/eEventTypes';
import eSymbolsAnimationDurations from './enums/eSymbolsAnimationDurations';

export default class Main extends FlashLib.MovieClip {
  constructor(data, displayItemData) {
    super(data, displayItemData);
    this.addListeners();
  }

  onGameResized(data) {
    if (data.isLandscape) {
      this.slotContainer.pivot.x = 0;
      this.slotContainer.x = this.slotContainer.displayData.x;
      this.slotContainer.y = this.slotContainer.displayData.y;
      this.slotContainer.scale.set(this.slotContainer.displayData.scaleX);
      this.peppersSlot.y = this.peppersSlot.displayData.y;
    } else if (data.isPortrait) {
      this.slotContainer.pivot.x = this.slotContainer.displayData.width / 2;
      this.slotContainer.x = this.slotContainer.displayData.x + this.slotContainer.displayData.width / 2 + 6;
      this.slotContainer.y = this.slotContainer.displayData.y + 140;
      this.slotContainer.scale.set(0.70);
      this.peppersSlot.y = this.peppersSlot.displayData.y + 90;
    } else {
      this.logo.y = this.logo.displayData.y + 4;
    }
  }

  init() {
    this.winningsController = new BaseSlotWinningsController({ nextWinTimeout: eSymbolsAnimationDurations.ESAD_DEFAULT });

    this.logo = this.getChildByName('logo');
    this.slotContainer = this.getChildByName('SlotWrapper');
    this.slot = this.slotContainer.getChildByName('slotContainer');
    this.reelsContainer = this.slot.getChildByName('reelsContainer');

    this.peppersSlot = this.getChildByName('peppersSlotContainer');
    this.peppersReelsContainer = this.peppersSlot.getChildByName('reelsContainer');
    this.backgroundContainer = this.getChildByName('BackgroundContainer');

    this.jackpotGameContainer = this.jackpotGame.getChildByName('jackpotGame');
    this.popupWonJackpotGame = this.jackpotGame.getChildByName('popupWonJackpotGame');
  }

  addListeners() {
    GlobalDispatcher.add(eEventTypes.EET_STATE_MANAGER__STATE_CHANGED, this.onStateChanged, this);
    window.OPWrapperService.eventManager.add(
      window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
      this.onGameResized,
      this
    );
  }

  onStateChanged(data) {
    switch (data.params) {
      case eStateTypes.EST_INIT:
        this.peppersSlot.playAnimation(this.peppersSlot.animationTypes.CIRCLE, 400000, 1500, 0, true);
        break;
      case eStateTypes.EST_IDLE:
        break;
      case eStateTypes.EST_REQUEST_ROLLING:
        this.peppersSlot.playAnimation(this.peppersSlot.animationTypes.SNAKE, 3000, 2000);
        this.peppersReelsContainer.start();
        this.reelsContainer.start();
        this.winningsController.reset();
        this.reelsContainer.setActive(true);
        break;
      case eStateTypes.EST_STOP_ROLLING:
        this.reelsContainer.stop(GameModel.reels);
        break;
      case eStateTypes.EST_SHOW_WIN:
        this.peppersSlot.playAnimation(this.peppersSlot.animationTypes.BLINK, 8000, 80);
        this.reelsContainer.setActive(false);
        this.winningsController.start();
        break;
      case eStateTypes.EST_SHOW_WON_JACKPOT_GAME:
        this.popupWonJackpotGame.show();
        break;
      case eStateTypes.EST_JACKPOT_GAME_IN:
        this.popupWonJackpotGame.hide();
        this.jackpotGameContainer.show();
        this.winningsController.reset();
        break;
      case eStateTypes.EST_JACKPOT_GAME_OUT:
        this.jackpotGameContainer.hide();
        this.winningsController.start();
        break;
    }
  }
}
