import FlashLib from 'flashlib_onlyplay';
import { gsap } from 'gsap';
import EntryPoint from 'Engine/EntryPoint';
import StatesManager from 'Engine/base/states/StatesManager';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import eServerClientStone from './eServerClientStone';
import SoundManager from 'Engine/soundManager/SoundManager';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import eSoundType from '../../../sounds/eSoundType';
import { wonJackpotGameFontStyle } from '../../../fontStyles';

export default class PopupWonJackpot extends FlashLib.MovieClip {
  constructor(data, $displayData) {
    super(data, $displayData);
    this.init();
  }
  init() {
    this.alpha = 0;
    this.visible = false;
    this.jackpotText = this.getChildByName('popup_won_jackpot_jackpot');
    this.jackpotAmount = this.getChildByName('jackpotAmount');
    this.background = this.getChildByName('background');
    this.jackpotTweenAmount = 0;
    this.setTextsSyle();
    this.addListeners()
  }

  addListeners () {
    GlobalDispatcher.add('panel: maxIcons', this.show, this);
  }

  setTextsSyle() {
    this.jackpotText.style = { ...this.jackpotText.style, ...wonJackpotGameFontStyle}
    this.jackpotAmount.style = { ...this.jackpotAmount.style, ...wonJackpotGameFontStyle}
  }

  show() {
    this.visible = true;
    gsap.to(this, { alpha: 1, duration: 0.6});

    this.jackpotEndValue = EntryPoint.GameModel.jackpotWinAmount;

    const prefix = window.OPWrapperService.config.jackpotEnabled ? 'popup_won_jackpot_' : 'popup_won_mystery_bonus_'
    this.jackpotText.text = window.OPWrapperService.localizations.getLocalizedText(`${prefix}${eServerClientStone[EntryPoint.GameModel.winJackpotType]}`)

    this.decimals = Math.min(this.jackpotEndValue.getPrecision(), EntryPoint.GameModel.currencyInfo.decimals);
    const arrForDecimals = MoneyFormat.formatCurrency(this.jackpotEndValue, this.decimals).split('.');
    this.decimalsForAnimation = arrForDecimals[1] ? arrForDecimals[1].length : 0;

    gsap.fromTo(this, { jackpotTweenAmount: 0}, { jackpotTweenAmount: this.jackpotEndValue, duration: 3, onComplete:() => {
        this.jackpotTweenAmount = 0;
        this.jackpotAmount.text = `${MoneyFormat.formatCurrency(this.jackpotEndValue, this.decimals)} ${EntryPoint.GameModel.currencyInfo.currency}`
        setTimeout(this.hide.bind(this), 2000)
      }});
    SoundManager.play(eSoundType.EST_JACKPOT_GAME_JACKPOT, 0.7, false, 'winning');
  }

  set jackpotTweenAmount(value) {
    this._jackpotTweenAmount = value;
    this.jackpotAmount.text = `${(this._jackpotTweenAmount ).toFixed(this.decimalsForAnimation)} ${EntryPoint.GameModel.currencyInfo.currency}`
  }

  get jackpotTweenAmount() {
    return this._jackpotTweenAmount;
  }
  hide(){
    StatesManager.goToNextState();
    gsap.to(this, { alpha: 0, duration: 0.6, onComplete: () => {
        this.visible = false
      }});
  }
}
