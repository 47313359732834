import GameModel from '../model/GameModel';
import InitState from 'Engine/states/InitState';
import IdleState from 'Engine/states/IdleState';
import RequestRollingState from './RequestRollingState';
import StopRollingState from './StopRollingState';
import ShowWinState from './ShowWinState';
import MinimalRollingState from './MinimalRollingState';
import ShowStartScreenState from './showStartScreenState';
import StopWinState from './StopWinState';
import eStateTypes from '../enums/eStateTypes';
import ShowWonJackpotGameState from './ShowWonJackpotGameState';
import JackpotGameInState from './JackpotGameInState';
import JackpotGameIdleState from './JackpotGameIdleState';
import JackpotGameOutState from './JackpotGameOutState';
import EntryPoint from 'Engine/EntryPoint';

export const StatesConfig = [
  {
    name: eStateTypes.EST_INIT, class: InitState, transitions: [
      {
        to: eStateTypes.EST_START_SCREEN, condition: function () {
          return true;
        }
      }
    ], entry: true
  },
  {
    name: eStateTypes.EST_IDLE, class: IdleState, transitions: [
      {
        to: eStateTypes.EST_REQUEST_ROLLING, condition: function () {
          return true;
        }
      },
    ]
  },
  {
    name: eStateTypes.EST_REQUEST_ROLLING, class: RequestRollingState, transitions: [
      {
        to: eStateTypes.EST_MINIMAL_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_MINIMAL_ROLLING, class: MinimalRollingState, transitions: [
      {
        to: eStateTypes.EST_STOP_ROLLING, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_STOP_ROLLING, class: StopRollingState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WIN, condition: function () {
          return GameModel.totalWin;
        }
      },
      {
        to: eStateTypes.EST_SHOW_WON_JACKPOT_GAME, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },

  {
    name: eStateTypes.EST_SHOW_WIN, class: ShowWinState, transitions: [
      {
        to: eStateTypes.EST_STOP_WIN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_STOP_WIN, class: StopWinState, transitions: [
      {
        to: eStateTypes.EST_SHOW_WON_JACKPOT_GAME, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_START_SCREEN, class: ShowStartScreenState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IN, condition: function () {
          return EntryPoint.GameModel.isJackpotGame;
        }
      },
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_SHOW_WON_JACKPOT_GAME, class: ShowWonJackpotGameState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IN, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_IN, class: JackpotGameInState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_IDLE, class: JackpotGameIdleState, transitions: [
      {
        to: eStateTypes.EST_JACKPOT_GAME_OUT, condition: function () {
          return true;
        }
      }
    ]
  },
  {
    name: eStateTypes.EST_JACKPOT_GAME_OUT, class: JackpotGameOutState, transitions: [
      {
        to: eStateTypes.EST_IDLE, condition: function () {
          return true;
        }
      }
    ]
  },
];
