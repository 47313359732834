import FlashLib from 'flashlib_onlyplay';

import BaseCheckBox from "Engine/base/ui/BaseCheckBox";
import Main from "./Main";
import ReelsContainer from "./controllers/reels/ReelsContainer";
import OverlayContainer from "Engine/containers/overlay/BaseOverlayContainer";
import Symbol from "./controllers/symbols/Symbol";
import StartScreenContainer from './controllers/startScreen/startScreen';
import BalanceContainer from "Engine/containers/ui/BaseBalanceContainer";
import WinContainer from "Engine/containers/ui/BaseWinContainer";
import PaytableContainer from "./controllers/paytable/PaytableContainer";
import PaytableContent from "./controllers/paytable/PaytableContent";
import PaytableControls from "./controllers/paytable/PaytableControls";
import SymbolAnimation from './controllers/symbols/SymbolAnimation';
import PopupWin from './controllers/popups/popupWin/PopupWin';
import BackgroundContainer from './controllers/background/BackgroundContainer';
import ControllerReels from 'Engine/base/reels/ControllerReels';
import ControllerSlot from './controllers/slot/ControllerSlot';
import BaseGameButton from './controllers/ui/BaseGameButton';
import ExtraReelsContainer from './controllers/extraReels/ExtraReelsContainer';
import ExtraCoefPanel from './controllers/extraReels/ExtraCoefPanel';
import SymbolsFrames from './controllers/symbols/SymbolsFrames';
import SymbolFire from './controllers/symbols/SymbolFire';
import BaseSettingsContainer from 'Engine/containers/setting/BaseSettingsContainer';
import ControllerJackpotPanels from './controllers/jackpot/JackpotPanels/ControllerJackpotPanels';
import ControllerPanel from './controllers/jackpot/jackpotGame/ControllerPanel';
import ControllerJackpotGame from './controllers/jackpot/jackpotGame/ControllerJackpotGame';
import ControllerStones from './controllers/jackpot/jackpotGame/ControllerStones';
import ControllerJackpotGamePlate from './controllers/jackpot/jackpotGame/ControllerJackpotGamePlate';
import ControllerJackpotGameField from './controllers/jackpot/jackpotGame/ControllerJackpotGameField';
import ControllerJackpotGamePanels from './controllers/jackpot/jackpotGame/ControllerJackpotGamePanels';
import ControllerStone from './controllers/jackpot/jackpotGame/ControllerStone';
import PopupWonJackpotGame from './controllers/jackpot/jackpotGame/PopupWonJackpotGame';
import PopupWonJackpot from './controllers/jackpot/jackpotGame/PopupWonJackpot';

FlashLib.registerClass('Button', BaseGameButton);
FlashLib.registerClass('CheckBox', BaseCheckBox);
FlashLib.registerClass('Main', Main);
FlashLib.registerClass('ReelsContainer', ReelsContainer);
FlashLib.registerClass('ExtraReelsContainer', ExtraReelsContainer);
FlashLib.registerClass('ExtraCoefPanel', ExtraCoefPanel);
FlashLib.registerClass('Symbol', Symbol);
FlashLib.registerClass('SettingsContainer', BaseSettingsContainer);
FlashLib.registerClass('StartScreenContainer', StartScreenContainer);
FlashLib.registerClass('BalanceContainer', BalanceContainer);
FlashLib.registerClass('WinContainer', WinContainer);
FlashLib.registerClass('OverlayContainer', OverlayContainer);
FlashLib.registerClass('PaytableContainer', PaytableContainer);
FlashLib.registerClass('PaytableContent', PaytableContent);
FlashLib.registerClass('PaytableControls', PaytableControls);
FlashLib.registerClass('SymbolAnimation', SymbolAnimation);
FlashLib.registerClass('PopupWin', PopupWin);
FlashLib.registerClass('BackgroundContainer', BackgroundContainer);
FlashLib.registerClass('ControllerReels', ControllerReels);
FlashLib.registerClass('ControllerSlot', ControllerSlot);
FlashLib.registerClass('SymbolsFrames', SymbolsFrames);
FlashLib.registerClass('SymbolFire', SymbolFire);

FlashLib.registerClass('ControllerJackpotPanels', ControllerJackpotPanels);
FlashLib.registerClass('ControllerPanel', ControllerPanel);
FlashLib.registerClass('ControllerJackpotGame', ControllerJackpotGame);
FlashLib.registerClass('ControllerStones', ControllerStones);
FlashLib.registerClass('ControllerJackpotGamePlate', ControllerJackpotGamePlate);
FlashLib.registerClass('ControllerJackpotGameField', ControllerJackpotGameField);
FlashLib.registerClass('ControllerJackpotGamePanels', ControllerJackpotGamePanels);
FlashLib.registerClass('ControllerStone', ControllerStone);
FlashLib.registerClass('PopupWonJackpotGame', PopupWonJackpotGame);
FlashLib.registerClass('PopupWonJackpot', PopupWonJackpot);
